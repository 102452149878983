import * as yup from "yup";
import "yup-phone-lite";

export const validationsVerifyAuthCodeSchema = yup.object({
  authCode: yup.string()
    .min(14, "The registration code must contain 14 characters")
    .max(14, "The registration code must contain 14 characters")
    .required("Enter your registration code"),
  cwdAuthCode: yup
    .string()
    .oneOf([yup.ref("authCode")], "Registration code does not match")
    .required("Confirm your registration code"),
})

export const validationsSignInSchema = yup.object({
  email: yup.string()
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup.string()
    .min(8, "Password must contain at least 8 characters")
    .required("Enter your password")
})

export const validationsSignUpSchema = yup.object({
  email: yup.string()
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup.string()
    .min(8, "Password must contain at least 8 characters")
    .required("Enter your password"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], "Password does not match")
    .required("Confirm your password"),
})

export const validationsUserInfoSchema = yup.object({
  firstName: yup.string().required("Required"),
  lastName: yup.string().required("Required"),
  phone: yup.string().phone().required("Required phone"),
  birthMonth: yup.string().required("Required"),
  birthDate: yup.string().required("Required"),
  birthYear: yup.string().required("Required")
})

export const validationsProfileEditFormSchema = yup.object({
  email: yup.string()
    .email("Enter a valid email")
    .required("Email is required"),
  firstName: yup.string().required("Required"),
  lastName: yup.string().required("Required"),
  phone: yup.string().phone().required("Required phone"),
  birthMonth: yup.string().required("Required"),
  birthDate: yup.string().required("Required"),
  birthYear: yup.string().required("Required")
})

export const validationsResetPasswordSchema = yup.object({
  password: yup.string()
    .min(8, "Password must contain at least 8 characters")
    .required("Enter your password"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], "Password does not match")
    .required("Confirm your password"),
})

export const validationsUpdatePasswordSchema = yup.object({
  password: yup.string()
    .min(8, "Password must contain at least 8 characters")
    .required("Enter your current password"),
  newPassword: yup.string()
    .min(8, "Password must contain at least 8 characters")
    .required("Enter your new password"),
  confirmNewPassword: yup
    .string()
    .oneOf([yup.ref("newPassword")], "New password does not match")
    .required("Confirm your new password"),
})