import {
  OAuthProvider,
  GoogleAuthProvider,
  FacebookAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  EmailAuthProvider,
  reauthenticateWithCredential,
  confirmPasswordReset,
  updatePassword,
  signOut,
} from "firebase/auth";
import {
  getFirestore,
} from "firebase/firestore"

import { firebaseApp } from "../config/firebase";
import { loginUrl } from "../config/urls";
import { showErrorToast } from "../utils/toast";
import { FIREBASE_AUTH_ERRORS } from "../constants/auth-errors";

const auth = getAuth(firebaseApp)
const db = getFirestore(firebaseApp)

const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();
const appleProvider = new OAuthProvider('apple.com');

appleProvider.addScope('email');
appleProvider.addScope('name');

const userData = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : {}

const logInWithEmailAndPassword = async (email, password) => {
  const {
    _tokenResponse: {
      idToken,
      refreshToken,
      expiresIn
    }
  } = await signInWithEmailAndPassword(auth, email, password);
  
  setTokens(idToken, refreshToken, expiresIn);
};

const registerWithEmailAndPassword = async (name, email, password) => {
  const {
    user,
    _tokenResponse: {
      // email,
      expiresIn,
      idToken,
      // kind,
      // localId,
      refreshToken
    }
  } = await createUserWithEmailAndPassword(auth, email, password);

  setTokens(idToken, refreshToken, expiresIn);

  return user
  // } catch (err) {
  //   console.error(err);
  //   alert(err.message);
    // Handle Errors here.
    // const errorCode = err.code;
    // const errorMessage = err.message;
    // // The email of the user's account used.
    // const email = err.customData.email;
    // // The credential that was used.
    // const credential = OAuthProvider.credentialFromError(err);
  // }
};

const signInWithGoogle = async (register = false) => {
  const {
    user: {
      stsTokenManager: { accessToken, refreshToken, expirationTime },
      // uid,
      // email,
      // displayName,
      // phoneNumber,
      // photoURL,
    }
  } = await signInWithPopup(auth, googleProvider).catch((error) => {
    const errorCode = error.code;

    showErrorToast({
      message: FIREBASE_AUTH_ERRORS?.[errorCode] || error.message
    })
  });

  setTokens(accessToken, refreshToken, expirationTime);
};

const signInWithFacebook = async (register) => {
  const {
    user: {
      stsTokenManager: { accessToken, refreshToken, expirationTime }
    }
  } = await signInWithPopup(auth, facebookProvider).catch((error) => {
    const errorCode = error.code;

    showErrorToast({
      message: FIREBASE_AUTH_ERRORS?.[errorCode] || error.message
    })
  });
  setTokens(accessToken, refreshToken, expirationTime);
};

const signInWithApple = async (register) => {
   const {
    user: {
      stsTokenManager: { accessToken, refreshToken, expirationTime }
    }
  } =  await signInWithPopup(auth, appleProvider).catch((error) => {
    const errorCode = error.code;

    showErrorToast({
      message: FIREBASE_AUTH_ERRORS?.[errorCode] || error.message
    })
  });
  
  setTokens(accessToken, refreshToken, expirationTime);
  // await registerUserData({
  //   uid,
  //   email,
  //   displayName,
  //   phoneNumber,
  //   photoURL,
  //   accessToken,
  //   refreshToken,
  //   expirationTime
  // }, register)

  // Apple credential
  // const credential = OAuthProvider.credentialFromResult(result);
  // const accessToken = credential.accessToken;
    // const idToken = credential.idToken;
}

const updateUserPassword = async (currentPassword, newPassword) => {
  const user = auth.currentUser;

  var credential = EmailAuthProvider.credential(
    user.email,
    currentPassword
  );

  // Prompt the user to re-provide their sign-in credentials
  await reauthenticateWithCredential(user, credential).then(async () => {
    // User re-authenticated.
    await updatePassword(user, newPassword)
    // .catch((error) => {
    //   showErrorToast({
    //     message: FIREBASE_AUTH_ERRORS?.[error.code] || error.message
    //   })
    // });
  });
}

const sendPasswordReset = async (email) => {
  await sendPasswordResetEmail(auth, email, {
    url: loginUrl,
  });
};

export const confirmThePasswordReset = async (oobCode, newPassword) => {
  if(!oobCode && !newPassword) return;
  
  return await confirmPasswordReset(auth, oobCode, newPassword)
}

const logout = async () => {
  await signOut(auth);

  setTokens(null, null, 0);
  setUserData({});
};

// const registerUserData = async (data, register) => {
//   const { email, displayName, uid, phoneNumber, photoURL, provider, accessToken, refreshToken, expirationTime } = data
//   if (register) {
    // const { data: user } = await Me.createMe(
    //   email,
    //   displayName.split(" ")[0],
    //   displayName.split(" ")[1],
    //   accessToken
    // );

    // setUserData({
    //   ...user,
    //   avatar_url: photoURL,
    //   login_type: provider.providerId,
    // });
//   } else {
//     setUserData({
//       id: uid,
//       firebase_id: uid,
//       email: email,
//       first_name: displayName.split(" ")[0],
//       last_name: displayName.split(" ")[1],
//       phone: phoneNumber,
//       birthdate: null,
//       level: "Registered",
//       avatar_url: photoURL,
//       login_type: provider.providerId,
//     });
//   }
//   setTokens(accessToken, refreshToken, expirationTime);
// }

const getTokens = () => {
  const accessToken = localStorage.getItem('accessToken') || null
  const refreshToken = localStorage.getItem('refreshToken') || null
  const tokenLifetime = localStorage.getItem('tokenLifetime') || null

  return {
    accessToken,
    refreshToken,
    tokenLifetime
  }
}

const setTokens = (token, refresh, expires) => {
  if (token) localStorage.setItem("accessToken", token);
  else localStorage.removeItem("accessToken");
  console.log("set access token");
  if (refresh) localStorage.setItem("refreshToken", refresh);
  else localStorage.removeItem("refreshToken");

  if (expires) localStorage.setItem("tokenLifetime", expires);
  else localStorage.removeItem("tokenLifetime");
};

const getUserData = () => {
  return localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : null
}

const setUserData = (data) => {
  Object.assign(userData, data);

  if (!Object.values(data).length) localStorage.removeItem("userData");
  else localStorage.set("userData", JSON.stringify(data));
};

export {
  userData,
  auth,
  db,
  getTokens,
  getUserData,
  setUserData,
  signInWithGoogle,
  signInWithFacebook,
  signInWithApple,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  updateUserPassword,
  logout,
};