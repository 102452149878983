export const getEnvironment = () => {
    // Extract the current environment from the hostname
    const hostname = window.location.hostname;
    if (hostname === "patient.descendantsdna.com") {
        return "live";
    }
    else if (hostname === "stage.descendantsdna.com") {
        return "stage";
    }
    else {
        return "dev";
    }
};
