import { createTheme } from '@mui/material'
import { ButtonThemeStyles } from './button-theme-styles'
import { BackgroundColors, Colors, PrimaryColors, TextColors } from './colors'
import { IconButtonBaseThemeStyles } from './icon-button-base-theme-styles'
import { TypographyThemeStyles } from './typography-theme-styles'

const mainTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      ssm: 425,
      sm: 600,
      md: 744,
      mmd: 834,
      lg: 1280,
      xl: 1440,
      xxl: 1920
    },
  },
  typography: {
    fontFamily: ['Poppins'].join(','),
    title: {
      fontWeight: '600',
      
      '@media (max-width:768px)': {
        fontSize: '24px',
        lineHeight: '29px',
      },
      '@media (min-width:768px)': {
        fontSize: '40px',
        lineHeight: '49px',
      },
    },
    sectionTitle: {
      fontWeight: '700',

      '@media (max-width:768px)': {
        fontSize: '24px',
        lineHeight: '35px',
      },
      '@media (min-width:768px)': {
        fontSize: '40px',
        lineHeight: '52px',
      },
    },
  },
  palette: {
    primary: {
      main: PrimaryColors.mainBlue,
      ...PrimaryColors
    },
    // secondary: {
    //   main: Colors.SecondPurple,
    //   contrastText: TextColors.white,
    // },
    text: {
      ...PrimaryColors
    },
    background: {
      ...BackgroundColors
    }
  },
  components: {
    MuiButton: ButtonThemeStyles,
    MuiTypography: TypographyThemeStyles,
    MuiIconButton: IconButtonBaseThemeStyles,
  },
})

export default mainTheme
