import { getEnvironment } from ".";

const environment = getEnvironment();
// Select the API URL based on the current environment
export const apiUrl =
  environment === "live"
    ? "https://api.descendantsdna.com/graphql"
    : environment === "stage"
      ? "https://stage-api.descendantsdna.com/graphql"
      : "https://dev-api.descendantsdna.com/graphql";

// Select the Physician panel URL based on the current environment
// This is used to redirect a physician user to the correct panel if they log in here
export const physicianUrl =
  environment === "live"
    ? "https://physician.descendantsdna.com/login"
    : environment === "stage"
      ? "https://stage-physician.descendantsdna.com/login"
      : "https://dev-physician.descendantsdna.com/login";

// Select the login URL based on the current environment
// This is currently only being used for password reset links
// We probably could have just done "https://{hostname}/login", but I decided to opt for consistency here
export const loginUrl =
  environment === "live"
    ? "https://patient.descendantsdna.com/login"
    : environment === "stage"
      ? "https://stage.descendantsdna.com/login"
      : "https://dev.descendantsdna.com/login";