export const Colors = {
  white: '#ffffff',
  black: '#0E0F10',
  gray_40: '#ECEFF1',
  gray_50: '#ABB7C0',
  gray_60: '#46545F',
  gray_70: '#0E0F10',
  
  mediumBlack: '#2A3342',
  darkBlack: '#1B1C1E',
  
  blue_30: '#C6E7FF',
  blue_40: '#5FB2EC',
  blue_50: '#5B87EF',
  blue_60: '#4266B8',

  red: '#E73E3E',
  light_red: '#F9DADA',
  yellow: '#FFCF54',
  light_yellow: '#FFF3D3',
  light_green_1: '#E3F6F2',
  light_green: '#BCEFE3',
  medium_green: '#62CDB4',
  dark_green: '#39B195',

  bg_blue_30: '#F6F9FC'
}

export const PrimaryColors = {
  mediumBlack: Colors.mediumBlack,
  blackGray: Colors.gray_70,
  darkBlack: Colors.darkBlack,
  darkGray: Colors.gray_60,
  mediumGray: Colors.gray_50,
  lightGray: Colors.gray_40,
  lightBlue: Colors.blue_30,
  mediumBlue: Colors.blue_40,
  mainBlue: Colors.blue_50,
  darkBlue: Colors.blue_60,
  red: Colors.red,
  lightRed: Colors.light_red,
  yellow: Colors.yellow,
  lightYellow: Colors.light_yellow,
  lightGreen1: Colors.light_green_1,
  lightGreen: Colors.light_green,
  mediumGreen: Colors.medium_green,
  darkGreen: Colors.dark_green,
  white: Colors.white,
  black: Colors.black,
  default: Colors.gray_60,
}

export const TextColors = {
  
}

export const BackgroundColors = {
  white: Colors.white,
  default: Colors.bg_blue_30,
  main: Colors.gray_50
}
