import { Tooltip } from 'react-tooltip'

const BaseReactTooltip = ({
  children,
  ...props
}) => {
  return (
    <Tooltip
      style={{
        background: '#ECEFF1',
        borderRadius: '8px',
        color: '#1B1C1E',
        boxShadow: '0px 2px 10px 0px #5B87EF33',
        zIndex: 20
      }}
      border="1px solid #ABB7C0"
      {...props}
    >
      {children}
    </Tooltip>
  )
}

export default BaseReactTooltip