// import { LoadingButton } from '@mui/lab';
import { LoadingButton } from '@mui/lab';
// import Button from '@mui/material/Button'; 

export default function BaseButton({
  children,
  variant,
  buttonType,
  color = 'white',
  width,
  minWidth,
  border,
  bgColor,
  loading,
  ...props
}) {
  let background = bgColor
  let borderColor = border
  let buttonColor = color
  if (buttonType === 'primary') {
    background = 'linear-gradient(82.43deg, #5A3BC7 7.57%, #5B87EF 88.27%)'
  }

  if (buttonType === 'secondary') {
    background = '#0E0F10'
  }
  if (buttonType === 'info') {
    background = '#FFFFFF1A'
  }
  if (buttonType === 'default') {
    background = 'white'
    borderColor = '#5B87EF'
    buttonColor = '#0E0F10'
  }

  const sx = {
    background: variant === 'outlined' ? 'transparent' : background,
    minWidth: minWidth,
    width: width || '100%',
    height: { xs: '43px', md: '43px' },
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: { xs: '16px', sm: '18px' },
    color: buttonColor,
    border: `1px solid ${borderColor}`,
    borderRadius: '8px',
    boxShadow: '0px 2px 10px 0px #5B87EF33',

    '&:hover': {
      border: `1px solid ${borderColor}`,
      background,
      // color: color
    },

    '&:focus': {
      outline: 'none',
    },

    '&:active': {
      border: `1px solid ${borderColor}`,
      background,
      // color: color
    },

    '&:disabled': {
      background: '#ABB7C0',
      color: 'white'
    },
    ...props.sx
  };

  return (
    <LoadingButton loading={loading} {...props} sx={sx}>
      {children}
    </LoadingButton>
  );
}