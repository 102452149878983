import { createSlice, configureStore } from '@reduxjs/toolkit'

const appSlice = createSlice({
    name: 'dDNA-app',
    initialState: {
      auth: null,
      loadingAuth: false,
      authMessage: null,
      theme: 'dark',
      headerTheme: null,
      refetchMe: false,
      openModal: false,
      currentModal: null,
      modalProps: {},
      expandSidebar: true,
      openMobileNav: false,
      showPreLoadingScreen: false,
      selectedFamily: null,
      reports: [],
      medicationFilterOptions: {},
      nutritionFilterOptions: {},
      openChatModal: false,
      lightboxImages: {
        isOpen: false,
        images: [],
        selectedIndex: 0
      },
      metaData: {
        themeColor: '#12274C'
      }
    },
    reducers: {
      setTheme: (state, action) => {
        state.theme = action.payload;
      },
      setHeaderTheme: (state, action) => {
        state.headerTheme = action.payload
      },
      refetchMe: (state, action) => {
        state.refetchMe = action.payload
      },
      openModal: (state, action) => {
        state.openModal = true;
        state.currentModal = action.payload.currentModal;
        state.modalProps = action.payload.modalProps;
      },
      closeModal: (state, action) => {
        state.openModal = false;
        state.currentModal = null;
        state.modalProps = null;
      },
      onModalConfirm: (state, action) => {
        state.params = action.payload.params
      },
      openMobileNav: (state, action) => {
        state.openMobileNav = action.payload;
      },
      setExpandSidebar: (state, action) => {
        state.expandSidebar = action.payload;
      },
      setShowPreLoadingScreen: (state, action) => {
        state.showPreLoadingScreen = action.payload;
      },
      setMetaData: (state, action) => {
        state.metaData = action.payload
      },
      setSelectedFamily: (state, action) => {
        state.selectedFamily = action.payload
      },
      setAuth: (state, action) => {
        state.auth = action.payload
      },
      setLoadingAuth: (state, action) => {
        state.loadingAuth = action.payload
      },
      setAuthMessage: (state, action) => {
        state.authMessage = action.payload
      },
      setReports: (state, action) => {
        state.reports = action.payload
      },
      setMedicationFilterOptions: (state, action) => {
        state.medicationFilterOptions = action.payload
      },
      setNutritionFilterOptions: (state, action) => {
        state.nutritionFilterOptions = action.payload
      },
      setOpenChatModal: (state, action) => {
        state.openChatModal = action.payload
      },
      setLightboxImages: (state, action) => {
        state.lightboxImages = action.payload
      }
    }
  })
  
  export const { 
    setTheme,
    setLocale,
    setHeaderTheme,
    refetchMe,
    openModal,
    closeModal,
    onModalConfirm,
    openMobileNav,
    setExpandSidebar,
    setShowPreLoadingScreen,
    setMetaData,
    setSelectedFamily,
    setAuth,
    setLoadingAuth,
    authMessage,
    setReports,
    setMedicationFilterOptions,
    setNutritionFilterOptions,
    setOpenChatModal,
    setLightboxImages
  } = appSlice.actions;
  
  const store = configureStore({
    reducer: appSlice.reducer,
  });
  
  export default store;