import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { Box, Typography } from "@mui/material"
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import InputPassword from "../../components/InputPassword";
import BaseButton from "../../components/BaseButton";
import BaseColorText from "../../components/BaseColorText";
import MainContainer from "../../components/Container/MainContainer";

import { validationsUpdatePasswordSchema } from "../../validations/validationSignUpSchema";
import { updateUserPassword, logInWithEmailAndPassword } from "../../api-services/auth";
import { showToast } from "../../utils/toast";
import { FIREBASE_AUTH_ERRORS } from "../../constants/auth-errors";
import BlockContainer from "../../components/Container/BlockContainer";

const ChangePassword = () => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const auth = useSelector(state => state.auth)
  const { errors, handleBlur, handleChange, handleSubmit, touched, values, setValues, setErrors, setTouched } =
    useFormik({
      validateOnBlur: true,
      initialValues: {
        password: '',
        newPassword: '',
        confirmNewPassword: ''
      },
      validationSchema: validationsUpdatePasswordSchema,
      onSubmit: async (values, { setSubmitting }) => {
        setIsLoading(true);
        updateUserPassword(values.password, values.newPassword)
        .then((data) => {
          setErrorMessage(null)
          setIsLoading(false);
          setValues({
            password: '',
            newPassword: '',
            confirmNewPassword: ''
          })
          setErrors({
            password: '',
            newPassword: '',
            confirmNewPassword: ''
          })
          setTouched({
            password: false,
            newPassword: false,
            confirmNewPassword: false
          })
          logInWithEmailAndPassword(auth?.email, values.newPassword)
          return showToast({
            message: 'Your password has been updated!'
          })
        })
        .catch(function(error) {
          // An error happened.
          if (error.code === 'auth/wrong-password') {
            setErrorMessage({
              name: 'password',
              error: 'The current password is invalid.'
            })
          } else {
            setErrorMessage({
              name: '',
              error: FIREBASE_AUTH_ERRORS?.[error.code] || error.message
            })
          }
        })
        .finally(() => {
          setIsLoading(false)
        })
      }
    });

    const handleChangeInput = (e) => {
      const value = e.target.value;
      if (value !== values.newPassword) {
        setErrors({
          confirmNewPassword: 'New password does not match'
        })
      }
      handleChange(e)
    }

  return (
    <MainContainer>
      <BlockContainer
        sx={{
          borderRadius: '16px',
          padding: '24px 16px',
          boxShadow: '0px 2px 10px 0px #5B87EF1A',
          bgcolor: 'background.white',
          height: '100%'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer'
          }}
          onClick={() => navigate(-1)}
        >
          <KeyboardBackspaceIcon 
            sx={{
              color: 'primary.darkGray',
              fontSize: '20px',
              mr: '5px'
            }}
          />
          <Typography variant="body" color='primary.darkGray'>
            Back to My Profile
          </Typography>
        </Box>
        <Box
          sx={{
            maxWidth: '600px',
            display: 'flex',
            flexDirection: 'column',
            mt: '24px'
          }}
        >
          <Typography variant="h2">
            Change password
          </Typography>
          <Typography variant="body" color="primary.darkGray" mt="8px">
            To change your current password, please fill in the fields below. 
            (Any additional description, like Your password must contain 8 characters, one upper case letter, and a special character, etc) 
          </Typography>
          <Box
            sx={{
              mt: '32px'
            }}
          >
            <form onSubmit={handleSubmit}>
              <InputPassword
                fullWidth
                label='Current Password'
                name='password'
                placeholder='Enter your password'
                value={values.password || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.password ? errors.password : ""}
                error={touched.password && Boolean(errors.password)}
                required
              />
              {errorMessage?.name === 'password' && 
                <Typography variant="subText">
                  <BaseColorText color='#E73E3E' variant='subText'>{errorMessage?.error}</BaseColorText>
                </Typography>
              }
              <InputPassword
                sx={{
                  mt: '24px',
                }}
                fullWidth
                label='New password'
                name='newPassword'
                placeholder='Create your new password'
                value={values.newPassword || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.newPassword ? errors.newPassword : ""}
                error={touched.newPassword && Boolean(errors.newPassword)}
                required
              />
              <InputPassword
                sx={{
                  mt: '24px',
                }}
                fullWidth
                label='Confirm new password'
                name='confirmNewPassword'
                placeholder='Confirm your new password'
                value={values.confirmNewPassword || ''}
                onChange={handleChangeInput}
                onBlur={handleBlur}
                helperText={errors.confirmNewPassword || ""}
                error={Boolean(errors.confirmNewPassword)}
                required
              />
              {(errorMessage && errorMessage?.name !== 'password') &&
                <Typography variant="subText">
                  <BaseColorText color='#E73E3E' variant='subText'>{errorMessage?.error}</BaseColorText>
                </Typography>
              }
              <BaseButton
                loading={isLoading}
                disabled={isLoading || Object.entries(errors).length !== 0}
                variant='contained'
                buttonType='primary'
                type='submit'
                sx={{
                  mt: '32px',
                }}
              >
                Change password
              </BaseButton>
            </form>
          </Box>
        </Box>
      </BlockContainer>
    </MainContainer>
)}

export default ChangePassword