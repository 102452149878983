import { forwardRef } from 'react'
import { Box, Typography, TextField, FormLabel  } from "@mui/material";
import { styled } from '@mui/material/styles';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import BaseColorText from '../BaseColorText';
import BaseReactTooltip from '../BaseReactTooltip';

const CssTextField = styled(TextField)(({ theme, error, InputProps }) => ({
  '& label.Mui-focused': {
    color: '#5B87EF',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#5B87EF',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: error ? '#ff0404' : InputProps.style?.border,
    },
    '&:hover fieldset': {
      borderColor: InputProps.style?.border,
    },
    '&.Mui-focused fieldset': {
      borderColor: error ? '#ff0404' : '#5B87EF',
    },
  },
}));

const BaseInput = forwardRef(({
  label,
  isHelpIcon,
  tooltipText,
  id,
  name,
  fullWidth = false,
  height = 47,
  message,
  error,
  sx,
  inputStyles = {
    color: '#46545F',
    fontWeight: 500,
    fontSize: '15px',
    border: '#ABB7C0',
    borderRadius: '8px',
    background: '#fff'
  },
  InputProps,
  required,
  ...props
}, ref) => {
  const inputStyle = {
    fontWeight: 500,
    fontSize: '15px',
    border: error ? '#ff0404' : '#ABB7C0',
    borderRadius: '8px',
    background: '#fff',
    ...inputStyles,
    color: error ? '#ff0404' : inputStyles.color,
  }
  return (
    <Box 
      sx={{
        width: '100%',
        display: 'inline-flex',
        flexDirection: 'column',
        ...sx,

        '& .MuiOutlinedInput-notchedOutline': {
          border: `1px solid ${inputStyle.border}`,

          '&:hover': {
            border: `1px solid ${inputStyle.border}`
          }
        },

        '& .MuiOutlinedInput-input': {
          height: height + 'px',
          paddingTop: 0,
          paddingBottom: 0,
        }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Box>
          {label && 
            <FormLabel
              sx={{
                fontSize: '12px',
                fontWeight: 500,
                mb: '1px',
                color: '#46545F'
              }}
              htmlFor={id}
            >
              {label}
              {required && <BaseColorText color='#E73E3E'>*</BaseColorText>}
            </FormLabel>
          }
        </Box>
        {isHelpIcon && (
          <Box>
            <HelpOutlineIcon 
              sx={{
                color: 'primary.mainBlue',
                fontSize: '18px',
                cursor: 'pointer'
              }}
              id={`${name}-input-help`}
            />
            <BaseReactTooltip
              anchorSelect={`#${name}-input-help`}
              place="bottom"
            >
              <Box
                sx={{
                  maxWidth: '327px',
                  color: 'primary.darkBlack'
                }}
              >
                <Typography variant='subText' whiteSpace='pre-wrap'>
                  {tooltipText}
                </Typography>
              </Box>
            </BaseReactTooltip>
          </Box>
        )}
      </Box>
      <CssTextField
        ref={ref}
        {...props}
        autoComplete='off'
        fullWidth={fullWidth}
        InputProps={{
          style: {
            ...inputStyle,
          },
          ...InputProps
        }}
        error={error}
        label={''}
        name={name}
      />
      {message && (
        <Typography variant='subText'>{message}</Typography>
      )}
    </Box>
  )
})

export default BaseInput
