import { styled } from '@mui/material/styles';

export default function BaseColorText({ color, gradientColor, children, isGradient }) {
  return (
    <>
      {isGradient ? 
        <GradientText gradientColor={gradientColor}>{children}</GradientText> : 
        <ColorText color={color}>{children}</ColorText>
      }
    </>
  )
}

const GradientText = styled('span')(({ theme, gradientColor }) => ({
  background: `-webkit-linear-gradient(45deg, ${gradientColor})`,
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent"
}))

const ColorText = styled('span')(({ theme, color }) => ({
  color
}))