/* eslint-disable default-case */
import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from '@apollo/client/link/context';
import { onError } from "@apollo/client/link/error";
import { showErrorToast } from "../utils/toast";
import { logout } from "../api-services/auth";
import { apiUrl } from "../config/urls";

const httpLink = new HttpLink({
  uri: apiUrl,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('accessToken');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      'X-Auth-Token': token || '',
    }
  }
});

export const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError, response, operation, forward }) => {
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }

      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path, extensions }) => {
          console.log(
            `[GraphQL error]: Message: ${message}`
          )
          showErrorToast({
            message
          });
          
          if (message === 'Unauthorized') {
            logout()
            // window.location.href = '/login'
          }
          
          switch (extensions?.code) {
            // Apollo Server sets code to UNAUTHENTICATED
            // when an AuthenticationError is thrown in a resolver
            case "UNAUTHENTICATED":
              // Modify the operation context with a new token
              // const oldHeaders = operation.getContext().headers;
              // operation.setContext({
              //   headers: {
              //     ...oldHeaders,
                  // authorization: getNewToken(),
                // },
              // });
              // Retry the request, returning the new observable
              return forward(operation);
          }
        });

        // Only notify the user if absolutely no data came back
        if (!response || !response.data) {
          // showErrorToast({
          //   message: ''
          // });
        }
      }
    }),
    authLink,
    httpLink,
  ]),
  cache: new InMemoryCache(),
});