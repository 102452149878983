import { Box } from "@mui/material"
// import { useSelector } from "react-redux"

const MainContainer = ({ children, sx }) => {
  // const isExpandedSidebar = useSelector(state => state.expandSidebar)

  return (
    <Box
      sx={{
        ml: { xs: 0, md: '237px' },
        mt: '80px',
        borderRadius: { md: '24px 0 0 24px' },
        padding: '24px 16px 50px',
        WebkitOverflowScrolling: 'touch',
        ...sx
      }}
      className='main-container'
    >
      {children}
    </Box>
  )
}

export default MainContainer