import { BackgroundColors, Colors, TextColors } from './colors'

export const ButtonThemeStyles = {
  styleOverrides: {
    root: {
      borderRadius: 8,
      padding: '8px 16px',
      height: '44px',
      fontSize: '15px',
      fontWeight: 500,
      lineHeight: '24px',
      textTransform: 'none',
      cursor: 'pointer',
      '&.MuiButton-sizeSmall': {
          fontSize: 14,
          padding: '4px 16px',
          height: 34,
      },
    },
  },
  variants: [
    {
      props: { variant: 'contained', type: 'primary' },
      style: {
        background: 'linear-gradient(45deg, #5A3BC7 30%, #5FB2EC 90%)',
        // color: TextColors.white,
        borderRadius: '8px',
        fontWeight: 500,
        textTransform: 'none',
        '&:disabled': {
            backgroundColor: BackgroundColors.lightGrey,
            // color: TextColors.default,
        },
        boxShadow: 'none',
        '&:hover': {
            boxShadow: 'none',
            background: Colors.white,
        },
      },
    },
    {
      props: { variant: 'outlined', type: 'secondary' },
      style: {
        background: Colors.white,
        color: Colors.black_neutrals,
        border: '1px solid',
        borderColor: Colors.PrimeBlue,
        textTransform: 'none',
        boxShadow: 'none',
        '&:hover': {
            border: '1px solid',
        },
        '&:disabled': {
            // color: TextColors.lightGrey,
        },
      },
    },
    {
      props: { variant: 'contained', type: 'default' },
      style: {
        textTransform: 'none',
        backgroundColor: Colors.SecondPurple,
        // color: TextColors.black,
        '&:hover': {
          // backgroundColor: BackgroundColors.darkGrey,
        },
      },
    },
  ],
}
