import { Box } from "@mui/material"

const BlockContainer = ({ children, sx }) => {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          width: { xs: '100%', xl: '1167px' },
          ...sx
        }}
      >
        {children}
      </Box>
    </Box>
  )
}

export default BlockContainer