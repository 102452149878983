import React from "react";
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material'
import { SkeletonTheme } from "react-loading-skeleton";
import { ApolloProvider } from '@apollo/client';
import { client } from "./apollo-client/client";
import Routes from "./routes";
import store from "./store";
import Theme from "./theme"
import NotifyContainer from "./components/NotifyContainer";

function App() {
  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
          <ThemeProvider theme={Theme}>
            <SkeletonTheme baseColor="#d9d9d9">
              <Routes />
              <NotifyContainer />
            </SkeletonTheme>
          </ThemeProvider>
      </Provider>
    </ApolloProvider>
  );
}

export default App;
