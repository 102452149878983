import { initializeApp } from "firebase/app";
import { getEnvironment } from ".";

const environment = getEnvironment();

const firebaseConfig =
  environment === "live"
    ? {
        measurementId: "",
        apiKey: "AIzaSyDo21AhNzc890x-4GKi3KV29X6XQwS4oM4",
        authDomain: "login.descendantsdna.com",
        projectId: "ddna-24f8f",
        storageBucket: "ddna-24f8f.appspot.com",
        messagingSenderId: "909304595258",
        appId: "1:909304595258:web:2f2319cb47b351ac138603",
      }
    : {
        measurementId: "",
        apiKey: "AIzaSyDQ0lg93xS0wmsJRW088clrqGSi35kVoFI",
        authDomain: "dev-login.descendantsdna.com",
        projectId: "ddna-portal-dev",
        storageBucket: "ddna-portal-dev.appspot.com",
        messagingSenderId: "661743439644",
        appId: "1:661743439644:web:4e0e8795110f0e0f16b4d9",
      };

export const firebaseApp = initializeApp(firebaseConfig);
