export const TypographyThemeStyles = {
  defaultProps: {
    variantMapping: {
      title: 'span',
      semibold: 'p',
      sectionTitle: 'p',
      bold: 'p'
    },
  },
  styleOverrides: {
    body1: {
      fontSize: '20px',
      lineHeight: '24px',
      fontWeight: 'normal',
    },
    h1: {
      fontSize: '25px',
      fontWeight: 700
    },
    h2: {
      fontSize: '20px',
      fontWeight: 600
    },
    h3: {
      fontSize: '17px',
      fontWeight: 600,
      lineHeight: '22.5px'
    },
    h4: {
      fontSize: '15px',
      fontWeight: 600,
      lineHeight: '22.5px'
    },
    body: {
      fontSize: '15px',
      fontWeight: 500,
      lineHeight: '22.5px',
      fontFamily: 'Poppins',
    },
    subText: {
      fontFamily: 'Poppins',
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '18px !important'
    }
  },
}
